import Vue from 'vue'
var moment = require('moment')//for get time

export default {
  parseEventStatisticsData: function (data, siteTimezone) {
    if (!(data instanceof Object)) return
    if (!(data.data instanceof Array)) return
    // INIT
    var currentTimestamp = Math.floor(new Date().getTime()/1000)
    let devicesStatistics = data.data
    let statisticsData = {}
    let statisticsDateUtc = []
    // COMBINE Timestamp, Value to one array
    for (let i=0; i<devicesStatistics.length; i++) {
      const deviceStatistics = devicesStatistics[i]
      // CHANGE - Timestamp to LocaleDateString / eg) - 2020, 10, 23(key): 23(value)
      for (let j=0; j<deviceStatistics.timestamps.length; j++) {
        // *Sometimes timestamp is weird. for protect calendar dates rendering error like "2099. 11. 26"
        if (deviceStatistics.timestamps[j] > currentTimestamp) continue
        let timestamp = deviceStatistics.timestamps[j] * 1000
        // *Safari(IOS) must fomat to YYYY-MM-DD. eg)2020-9-5 will be error.
        let timestampFormatDate = moment(timestamp).tz(siteTimezone).format('YYYY-MM-DD')
        // Eevnt Value in Array
        if (!(timestampFormatDate in statisticsData)) statisticsData[timestampFormatDate] = 0
        statisticsData[timestampFormatDate] += +deviceStatistics.values[j]
      }
    }
    let statisticsDates = Object.keys(statisticsData)
    // CHANGE - LocaleDateString to UTC time / eg) - Thu Jul 16 2020 00:00:00 GMT+0900 (대한민국 표준시)
    for (let i=0; i<statisticsDates.length; i++) {
      const statisticsDate = statisticsDates[i]
      statisticsDateUtc.push(moment(statisticsDate).toDate())
    }
    // DONE
    return statisticsDateUtc
  },
  parseDeviceEventStatisticsData: function (data, siteTimezone, deviceId) {
    if (!(data instanceof Object)) return;
    if (!(data.data instanceof Array)) return;

    var currentTimestamp = Math.floor(new Date().getTime() / 1000);
    let statisticsDeviceData = {};
    let deviceStatisticsDateUtc = [];

    let targetDeviceData = data.data.find(item => item.device_id === deviceId.toString());

    for (let j = 0; j < targetDeviceData.timestamps.length; j++) {
      if (targetDeviceData.timestamps[j] > currentTimestamp) continue; 

      let timestamp = targetDeviceData.timestamps[j] * 1000;
      let timestampFormatDate = moment(timestamp).tz(siteTimezone).format('YYYY-MM-DD');

      if (!(timestampFormatDate in statisticsDeviceData)) {
        statisticsDeviceData[timestampFormatDate] = 0;
      }
      statisticsDeviceData[timestampFormatDate] += +targetDeviceData.values[j];
    }

    let deviceStatisticsDatasKey = Object.keys(statisticsDeviceData);
    for (let i = 0; i < deviceStatisticsDatasKey.length; i++) {
      const deviceStatisticsDate = deviceStatisticsDatasKey[i];
      deviceStatisticsDateUtc.push(moment(deviceStatisticsDate).toDate());
    }

  return deviceStatisticsDateUtc;
}

}