<template>
	<div class="marketing">
		<!-- header -->

		<!-- content -->
		<div class="content">
			<div class="header">
				<div class="close-button" @click="onClickClose()">
					<ion-icon name="close-outline"></ion-icon>
				</div>
			</div>
			<video class="video" ref="video" playsinline loop autoplay muted src="/myimg/log_thumbnail.mp4"></video>
			<div class="label">
				NEW
			</div>
			<div class="title">
				Hover to Preview, New look
			</div>
			<div class="desc">
				Hover an activity event to see a quick preview.
				Get a glimpse of the action before watching!
			</div>
			<div class="button">
				<ion-button @click="onClickGotIt()" color="primary" expand="block">Got it</ion-button>
			</div>
		</div>

	</div>
</template>
  
<script>
export default {
	props: {
		store: { type: Object, default: function () { return {} } },
		marketingVersion: { type: String, default: "" },
	},
	data: function () {
		return {
		}
	},
	watch: {
	},
	created: function () {
	},
	mounted: function () {
		this.$refs.video.playbackRate = 1
	},
	methods: {
		onClickGotIt:function () {
			this.$tool.storageSetItem("chekt_marketing", this.marketingVersion)
			this.onClickClose()
		},	
		onClickClose: function () {
      this.$el.parentNode.removeChild(this.$el) // DOM에서 제거
      this.$destroy() // Vue 인스턴스 제거
		},
	}
}
</script>
  
<style scoped>
.marketing {
	height: 100%;
	width: 100%;
	z-index: 1;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}
.content{
	position: relative;
	height: 100%;
	width: 100%;
	max-width: 520px;
	max-height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	background-color: white;
	border-radius: 20px;
	overflow: hidden;
	margin: 20px;
	animation-name: popup;
	animation-duration: 300ms;
	animation-timing-function: ease-out;
}
.close-button {
	position: absolute;
	background-color: rgb(0 0 0 / 40%);
	border-radius: 100%;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 16px;
	right: 16px;
	z-index: 9999;
	color: white;
	cursor: pointer;
}
.close-button:hover {
	background-color: rgb(0 0 0 / 50%);
}
.label {
	margin-top: 48px;
	font-size: 12px;
	font-weight: 600;
	color: var(--ion-color-primary);
	background-color: rgba(56, 129, 255, 0.1);
	padding: 5px 7px;
	border-radius: 5px;
}
.title {
	margin-top: 5px;
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}
.desc {
	font-size: 14px;
	margin-top: 10px;
	margin-left: 30px;
	margin-right: 30px;
	color: var(--ion-text-medium);
	text-align: center;
}
.button {
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: end;
	margin: 14px 20px;
	width: 100%;
}
.video {
	width: 100%;
}
@keyframes popup {
  from {
    transform: scale(0.9);
		opacity: 0;
  }

  to {
    transform: scale(1);
		opacity: 1;
  }
}
</style>