<template>
  <div>
    <ViewInactiveAlert v-if="showInactiveAlert" @onClickResume="onClickResume" @onClickContinue="onClickContinue"/>
  </div>
</template>

<script>
import ViewInactiveAlert from '@/components/modals/ViewInactiveAlert'
export default {
  props: {
    clickedResumeButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ViewInactiveAlert,
  },
  data() {
    return {
      showInactiveAlert: false,
      inactiveTimer: 1000 * 60 * 5, // 5분
    };
  },
  created() {
    this.startCheckUserMouseMoving()
  },
  mounted() {
  },
  beforeDestroy() {
    this.stopCheckUserMouseMoving()
  },
  methods: {
    startCheckUserMouseMoving: function () {
      document.addEventListener('mousemove', this.listenMouseMove)
      this.listenMouseMove()
    },
    stopCheckUserMouseMoving: function () {
      document.removeEventListener('mousemove', this.listenMouseMove)
    },
    listenMouseMove: function () {
      if (this.mouseMove$t) {
        clearTimeout(this.mouseMove$t);
        this.mouseMove$t = null;
      }

      this.mouseMove$t = setTimeout(async() => {
        this.showInactiveAlert = true;
      }, this.inactiveTimer);
    },
    onClickResume: async function () {
      this.showInactiveAlert = false;
      await this.$webrtc.requestAllPeers()
    },
    onClickContinue: async function () {
      this.showInactiveAlert = false;
      if (this.mouseMove$t) {
        clearTimeout(this.mouseMove$t);
        this.mouseMove$t = null;
      }
    },
  }
}
</script>

<style>

</style>