import Vue from 'vue'
import {
  RESET_STATE,
  IS_APP_ACTIVE,
  IS_APP_USE,
  IS_FULLSCREEN,
  CURRENT_APP_ORIENTATION_TYPE,
  IS_NETWORK_CONNECTED,
  IS_SCREEN_ORIENTATION_LOCK
} from '@/store/mutation-types'

let initialState = { 
  isAppActive: null,
  isAppUse: true,
  isFullscreen: null,
  isNetworkConnected: true, // default true가 안전할 것 으로 판단.
  networkType: null,
  isScreenOrientationLock: null,
  currentAppOrientationType: null,
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  isAppActive: function (state) {
    return state.isAppActive
  },
  isAppUse: function (state) {
    return state.isAppUse
  },
  isFullscreen: function (state) {
    return state.isFullscreen
  },
  currentAppOrientationType: function (state) {
    return state.currentAppOrientationType
  },
  isScreenOrientationLock: function (state) {
    return state.isScreenOrientationLock
  },
  isNetworkConnected: function (state) {
    return state.isNetworkConnected
  },
  networkType: function (state) {
    return state.networkType
  },
}

// actions
const actions = {
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [IS_APP_ACTIVE]: function (state, isAppActive) {
    state.isAppActive = isAppActive
  },
  [IS_APP_USE]: function (state, isAppUse) {
    state.isAppUse = isAppUse
  },
  [IS_FULLSCREEN]: function (state, isFullscreen) {
    state.isFullscreen = isFullscreen
  },
  [CURRENT_APP_ORIENTATION_TYPE]: function (state, type) {
    state.currentAppOrientationType = type
  },
  [IS_SCREEN_ORIENTATION_LOCK]: function (state, isLock) {
    state.isScreenOrientationLock = isLock
  },
  [IS_NETWORK_CONNECTED]: function (state, network) {
    // true, false
    state.isNetworkConnected = network.connected
    // cellular, wifi, none
    state.networkType = network.connectionType
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}