import Vue from 'vue'
import api from '@/apis/siteProxySpeaker'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,

  // GETs
  GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE,
  GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS,

  GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE,
} from '@/store/mutation-types'


function isOnvifDataGivesSuccess(data) {
  let result = true
  
  if (data.data && 'Fault' in data.data) result = false
  if (Array.isArray(data?.errors) && data?.errors?.length) result = false
  if (!Array.isArray(data?.errors) && data?.errors) result = false
  if (data?.error) result = false;

  // 에러 알려주기.
  if (!result) console.warn('ℹ️ proxy api is succeeded but, onvif gives errors in bypassed data')

  return result
}

let initialState = {
  camerasOnvifSpeakerConfig: {},
  camerasOnvifSpeakerConfigOptions: {},
  // setCameraOnvifSpeakerConfig: {},
  status: {
    camerasOnvifSpeakerConfig: null,
    camerasOnvifSpeakerConfigOptions: null,
    setCameraOnvifSpeakerConfig: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  camerasOnvifSpeakerConfig: function (state) {
    return state.camerasOnvifSpeakerConfig
  },
  cameraOnvifSpeakerConfig: function (state, getters) {
    return function (deviceId) {
      return getters.camerasOnvifSpeakerConfig[deviceId]
    }
  },
  camerasOnvifSpeakerConfigOptions: function (state) {
    return state.camerasOnvifSpeakerConfigOptions
  },
  cameraOnvifSpeakerConfigOptions: function (state, getters) {
    return function (deviceId) {
      return getters.camerasOnvifSpeakerConfigOptions[deviceId]
    }
  },
  statusSetCameraOnvifSpeakerConfigFromProxy: function (state) {
    return state.status.setCameraOnvifSpeakerConfig
  }
}

// actions
const actions = {
  getSiteOnvifSpeakerConfigFromProxy: function ({commit, state}, {siteId, bridgeId, channel, deviceId}) {
    if (state.camerasOnvifSpeakerConfig?.[deviceId]) return

    commit(GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifSpeakerConfigFromProxy({siteId, bridgeId, channel}).then(
        async (res) => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) {
            api.getOnvifSpeakerConfigFromProxyOld({siteId, bridgeId, channel}).then(
              res => {
                let data = res.body
                commit(GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS, {data, deviceId})
                resolve()
                commit(PROCESS_API_SUCCESS)
              },
              err => {
                commit(GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE)
                reject({
                  status: err.status,
                  statusText: err.body,
                })
                commit(PROCESS_API_FAILURE, {
                  status: err.status,
                  statusText: err.body,
                  origin: window.location.origin,
                  err: Vue.tool.parseToStringify(err),
                })
              }
            )
          }
          else {
            commit(GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS, {data, deviceId})
            resolve()
            commit(PROCESS_API_SUCCESS)
          }
        },
        err => {
          api.getOnvifSpeakerConfigFromProxyOld({siteId, bridgeId, channel}).then(
            res => {
              let data = res.body
              commit(GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS, {data, deviceId})
              resolve()
              commit(PROCESS_API_SUCCESS)
            },
            err => {
              commit(GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE)
              reject({
                status: err.status,
                statusText: err.body,
              })
              commit(PROCESS_API_FAILURE, {
                status: err.status,
                statusText: err.body,
                origin: window.location.origin,
                err: Vue.tool.parseToStringify(err),
              })
            }
          )
        }
      )
    })
  },
  getSiteOnvifSpeakerConfigOptionsFromProxy: function ({commit, state}, {siteId, bridgeId, channel, deviceId}) {
    if (state.camerasOnvifSpeakerConfigOptions[deviceId]) return

    commit(GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifSpeakerConfigOptionsFromProxy({siteId, bridgeId, channel}).then(
        async (res) => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) {
            api.getOnvifSpeakerConfigOptionsFromProxyOld({siteId, bridgeId, channel}).then(
              res => {
                let data = res.body
                commit(GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_SUCCESS, {data, deviceId})
                resolve()
                commit(PROCESS_API_SUCCESS)
              },
              err => {
                commit(GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_FAILURE)
                reject({
                  status: err.status,
                  statusText: err.body,
                })
                commit(PROCESS_API_FAILURE, {
                  status: err.status,
                  statusText: err.body,
                  origin: window.location.origin,
                  err: Vue.tool.parseToStringify(err),
                })
              }
            )
          }
          else {
            commit(GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_SUCCESS, {data, deviceId})
            resolve()
            commit(PROCESS_API_SUCCESS)
          }
        },
        err => {
          api.getOnvifSpeakerConfigOptionsFromProxyOld({siteId, bridgeId, channel}).then(
            res => {
              let data = res.body
              commit(GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_SUCCESS, {data, deviceId})
              resolve()
              commit(PROCESS_API_SUCCESS)
            },
            err => {
              commit(GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_FAILURE)
              reject({
                status: err.status,
                statusText: err.body,
              })
              commit(PROCESS_API_FAILURE, {
                status: err.status,
                statusText: err.body,
                origin: window.location.origin,
                err: Vue.tool.parseToStringify(err),
              })
            }
          )
        }
      )
    })
  },
  setSiteOnvifSpeakerConfigFromProxy: function ({commit}, {siteId, bridgeId, deviceId, channel, audioOutputConfigToken, audioOutputToken, volume}) {
    commit(SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setOnvifSpeakerConfigFromProxy({siteId, bridgeId, channel, audioOutputConfigToken, audioOutputToken, volume}).then(
        async (res) => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) {
            api.setOnvifSpeakerConfigFromProxyOld({siteId, bridgeId, channel, audioOutputConfigToken, audioOutputToken, volume}).then(
              res => {
                let data = res.body
                commit(SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS, {volume, deviceId})
                resolve()
                commit(PROCESS_API_SUCCESS)
              },
              err => {
                commit(SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE)
                reject({
                  status: err.status,
                  statusText: err.body,
                })
                commit(PROCESS_API_FAILURE, {
                  status: err.status,
                  statusText: err.body,
                  origin: window.location.origin,
                  err: Vue.tool.parseToStringify(err),
                })
              }
            )
          }
          else {
            commit(SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS, {volume, deviceId})
            resolve()
            commit(PROCESS_API_SUCCESS)
          }
        },
        err => {
          api.setOnvifSpeakerConfigFromProxyOld({siteId, bridgeId, channel, audioOutputConfigToken, audioOutputToken, volume}).then(
            res => {
              let data = res.body
              commit(SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS, {volume, deviceId})
              resolve()
              commit(PROCESS_API_SUCCESS)
            },
            err => {
              commit(SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE)
              reject({
                status: err.status,
                statusText: err.body,
              })
              commit(PROCESS_API_FAILURE, {
                status: err.status,
                statusText: err.body,
                origin: window.location.origin,
                err: Vue.tool.parseToStringify(err),
              })
            }
          )
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST]: function (state) {
    state.status.camerasOnvifSpeakerConfig = 'requested'
  },
  [GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    let audioOutputConfig = data?.data?.GetAudioOutputConfigurationsResponse?.Configurations

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasOnvifSpeakerConfig[deviceId]) {
      Vue.set(state.camerasOnvifSpeakerConfig, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasOnvifSpeakerConfig, deviceId, audioOutputConfig);

    state.status.camerasOnvifSpeakerConfig = 'successful'
  },
  [GET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE]: function (state) {
    state.status.camerasOnvifSpeakerConfig = 'failed'
  },
  [GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_REQUEST]: function (state) {
    state.status.camerasOnvifSpeakerConfigOptions = 'requested'
  },
  [GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    let audioOutputConfigOptions = data?.data?.GetAudioOutputConfigurationOptionsResponse?.Options

    // 없는 경우 빈 객체를 먼저 설정
    if (!state.camerasOnvifSpeakerConfigOptions[deviceId]) {
      Vue.set(state.camerasOnvifSpeakerConfigOptions, deviceId, {});
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.camerasOnvifSpeakerConfigOptions, deviceId, audioOutputConfigOptions);

    state.status.camerasOnvifSpeakerConfigOptions = 'successful'
  },
  [GET_SITE_ONVIF_SPEAKER_CONFIG_OPTIONS_FROM_PROXY_FAILURE]: function (state) {
    state.status.camerasOnvifSpeakerConfigOptions = 'failed'
  },
  [SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_REQUEST]: function (state) {
    state.status.setCameraOnvifSpeakerConfig = 'requested'
  },
  [SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_SUCCESS]: function (state, {volume, deviceId}) {
    const target = state.camerasOnvifSpeakerConfig[deviceId]
    // 없는 경우 빈 객체를 먼저 설정
    if (!target) {
      Vue.set(state.camerasOnvifSpeakerConfig, deviceId, {});
      target.Configurations.OutputLevel = volume + '' ? volume + '' : "0"
    } else {
      target.OutputLevel = volume + '' ? volume + '' : "0"
    }

    Vue.set(state.camerasOnvifSpeakerConfig, deviceId, target);
    state.status.setCameraOnvifSpeakerConfig = 'successful'
  },
  [SET_SITE_ONVIF_SPEAKER_CONFIG_FROM_PROXY_FAILURE]: function (state) {
    state.status.setCameraOnvifSpeakerConfig = 'failed'
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}