import Vue from 'vue'
import api from '@/apis/siteProxyCameraRelay'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,

  // GETs
  GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_FAILURE,

  GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_REQUEST,
  GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_SUCCESS,
  GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_FAILURE,

  // SETs
  SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_FAILURE,

  SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_REQUEST,
  SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_SUCCESS,
  SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_FAILURE,

  // RESETs
  RESET_SITE_ONVIF_RELAY_FROM_PROXY,

  // OTHER
  SET_RELAY_FINISH_TIME,
} from '@/store/mutation-types'


function isOnvifDataGivesSuccess(data) {
  let result = true
  
  if (data.data && 'Fault' in data.data) result = false
  if (Array.isArray(data?.errors) && data?.errors?.length) result = false
  if (!Array.isArray(data?.errors) && data?.errors) result = false
  if (data?.error) result = false;

  // 에러 알려주기.
  if (!result) console.warn('ℹ️ proxy api is succeeded but, onvif gives errors in bypassed data')

  return result
}

let initialState = {
  onvifsRelayInfos: {},
  onvifsRelayOutputSettings: {},
  relaysMomentaryFinishTime: {},
  status: {
    onvifsRelayInfos: null,
    onvifRelayInfos: null,
    onvifRelayOutputSettings: null,
    setOnvifsRelayOutputState: {},
    getOnvifRelayOutputOptions: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  onvifsRelayInfos: function (state) {
    return state.onvifsRelayInfos
  },
  onvifRelayInfos: function (state, getters) {
    return function (deviceId) {
      return getters.onvifsRelayInfos[deviceId]
    }
  },
  statusOnvifsRelayInfos: function (state, getters) {
    return getters.status.onvifsRelayInfos
  },
  relaysMomentaryFinishTime: function (state) {
    return state.relaysMomentaryFinishTime
  },
  relayMomentaryFinishTime: function (state, getters) {
    return function (siteId, deviceId, relayToken) {
      if (!getters.relaysMomentaryFinishTime[siteId]) return null
      if (!getters.relaysMomentaryFinishTime[siteId][deviceId]) return null

      return getters.relaysMomentaryFinishTime[siteId][deviceId][relayToken] || null
    }
  },
  statusSetOnvifsRelayOutputState: function (state) {
    return state.status.setOnvifsRelayOutputState
  },
  statusSetOnvifRelayOutputState: function (state, getters) {
    return function (deviceId, relayToken) {
      if (!getters.statusSetOnvifsRelayOutputState[deviceId]) return null
      if (!getters.statusSetOnvifsRelayOutputState[deviceId][relayToken]) return null
      return getters.statusSetOnvifsRelayOutputState[deviceId][relayToken] || null
    }
  } 
}

// actions
const actions = {
  getSiteOnvifRelayOutputsFromProxy: function ({commit, state, dispatch}, {siteId, bridgeId, channel, deviceId}) {
    if (state.onvifsRelayInfos[deviceId]) return

    commit(GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifRelayOutputsFromProxy({siteId, bridgeId, channel}).then(
        async (res) => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) {
            api.getOnvifRelayOutputsFromProxyOld({siteId, bridgeId, channel}).then(
              res => {
                let data = res.body
                commit(GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_SUCCESS, {data, deviceId})
                resolve()
                commit(PROCESS_API_SUCCESS)
              },
              err => {
                commit(GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_FAILURE)
                reject({
                  status: err.status,
                  statusText: err.body,
                })
                commit(PROCESS_API_FAILURE, {
                  status: err.status,
                  statusText: err.body,
                  origin: window.location.origin,
                  err: Vue.tool.parseToStringify(err),
                })
              }
            )
          }
          else {
            commit(GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_SUCCESS, {data, deviceId})
            resolve()
            commit(PROCESS_API_SUCCESS)
          }
        },
        err => {
          api.getOnvifRelayOutputsFromProxyOld({siteId, bridgeId, channel}).then(
            res => {
              let data = res.body
              commit(GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_SUCCESS, {data, deviceId})
              resolve()
              commit(PROCESS_API_SUCCESS)
            },
            err => {
              commit(GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_FAILURE)
              reject({
                status: err.status,
                statusText: err.body,
              })
              commit(PROCESS_API_FAILURE, {
                status: err.status,
                statusText: err.body,
                origin: window.location.origin,
                err: Vue.tool.parseToStringify(err),
              })
            }
          )
        }
      )
    })
  },
  getSiteOnvifRelayOutputOptionsFromProxy: function ({commit}, {siteId, bridgeId, channel, deviceId}) {
    commit(GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.getOnvifRelayOutputOptionsFromProxy({siteId, bridgeId, channel}).then(
        res => {
          let data = res.body
          commit(GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_SUCCESS, {data, deviceId})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  setSiteOnvifRelayOutputStateFromProxy: function ({commit}, {siteId, bridgeId, channel, relayToken, state, deviceId}) {
    commit(SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_REQUEST, {deviceId, relayToken})
    return new Promise((resolve, reject) => {
      api.setOnvifRelayOutputStateFromProxy({siteId, bridgeId, channel, relayToken, state}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) {
            api.setOnvifRelayOutputStateFromProxyOld({siteId, bridgeId, channel, relayToken, state}).then(
              res => {
                data = res.body
                if (!isOnvifDataGivesSuccess(data)) {
                  commit(SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_FAILURE, {deviceId, relayToken})
                  reject()
                }
                else {
                  commit(SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_SUCCESS, {deviceId, relayToken})
                  resolve()
                  commit(PROCESS_API_SUCCESS)
                }
              },
              err => {
                commit(SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_FAILURE, {deviceId, relayToken})
                reject({
                  status: err.status,
                  statusText: err.body,
                })
                commit(PROCESS_API_FAILURE, {
                  status: err.status,
                  statusText: err.body,
                  origin: window.location.origin,
                  err: Vue.tool.parseToStringify(err),
                })
              }
            )
          }
          else {
            commit(SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_SUCCESS, {deviceId, relayToken})
            resolve()
            commit(PROCESS_API_SUCCESS)
          }
        },
        err => {
          api.setOnvifRelayOutputStateFromProxyOld({siteId, bridgeId, channel, relayToken, state}).then(
            res => {
              commit(SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_SUCCESS, {deviceId, relayToken})
              resolve()
              commit(PROCESS_API_SUCCESS)
            },
            err => {
              commit(SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_FAILURE, {deviceId, relayToken})
              reject({
                status: err.status,
                statusText: err.body,
              })
              commit(PROCESS_API_FAILURE, {
                status: err.status,
                statusText: err.body,
                origin: window.location.origin,
                err: Vue.tool.parseToStringify(err),
              })
            }
          )
        }
      )
    })
  },

  setSiteRelayOutputSettingsFromProxy: function ({commit, state}, {siteId, bridgeId, channel, relayToken, delayTime, deviceId}) {
    // if (state.onvifsRelayOutputSettings[deviceId]) return
    commit(SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_REQUEST)
    return new Promise((resolve, reject) => {
      api.setRelayOutputSettingsFromProxy({siteId, bridgeId, channel, relayToken, delayTime}).then(
        res => {
          let data = res.body

          if (!isOnvifDataGivesSuccess(data)) {
            api.setRelayOutputSettingsFromProxyOld({siteId, bridgeId, channel, relayToken, delayTime}).then(
              res => {
                data = res.body
                if (isOnvifDataGivesSuccess(data)) {
                  commit(SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_SUCCESS, {data, deviceId})
                  resolve()
                  commit(PROCESS_API_SUCCESS)
                }
                else {
                  commit(SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_FAILURE)
                  reject()
                }
              },
              err => {
                commit(SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_FAILURE)
                reject({
                  status: err.status,
                  statusText: err.body,
                })
                commit(PROCESS_API_FAILURE, {
                  status: err.status,
                  statusText: err.body,
                  origin: window.location.origin,
                  err: Vue.tool.parseToStringify(err),
                })
              }
            )
          }
          else {
            commit(SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_SUCCESS, {data, deviceId})
            resolve()
            commit(PROCESS_API_SUCCESS)
          }
        },
        err => {
          api.setRelayOutputSettingsFromProxyOld({siteId, bridgeId, channel, relayToken, delayTime}).then(
            res => {
              commit(SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_SUCCESS, {data, deviceId})
              resolve()
              commit(PROCESS_API_SUCCESS)
            },
            err => {
              commit(SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_FAILURE)
              reject({
                status: err.status,
                statusText: err.body,
              })
              commit(PROCESS_API_FAILURE, {
                status: err.status,
                statusText: err.body,
                origin: window.location.origin,
                err: Vue.tool.parseToStringify(err),
              })
            }
          )
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },

  [GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_REQUEST]: function (state) {
    state.status.onvifRelayInfos = 'requested'
  },
  [GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    if (!deviceId) return
    if (!data.data) return
    if (!(data.data instanceof Object)) return
    if (!data.data.GetRelayOutputsResponse) return
    
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.onvifsRelayInfos[deviceId]) {
      Vue.set(state.onvifsRelayInfos, deviceId, {});
    }

    let relays = data.data.GetRelayOutputsResponse?.RelayOutputs
    
    if (typeof relays === 'object'  && relays !== null && !Array.isArray(relays)) {
      relays = [relays]
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.onvifsRelayInfos, deviceId, relays);

    state.status.onvifRelayInfos = 'successful'
  },
  [GET_SITE_ONVIF_RELAY_OUTPUTS_FROM_PROXY_FAILURE]: function (state) {
    state.status.onvifRelayInfos = 'failed'
  },
  
  [SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_REQUEST]: function (state, {deviceId, relayToken}) {
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.status.setOnvifsRelayOutputState[deviceId]) {
      Vue.set(state.status.setOnvifsRelayOutputState, deviceId, {});
    }

    Vue.set(state.status.setOnvifsRelayOutputState[deviceId], relayToken, 'requested');
  },
  [SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_SUCCESS]: function (state, {deviceId, relayToken}) {
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.status.setOnvifsRelayOutputState[deviceId]) {
      Vue.set(state.status.setOnvifsRelayOutputState, deviceId, {});
    }

    Vue.set(state.status.setOnvifsRelayOutputState[deviceId], relayToken, 'successful');
  },
  [SET_SITE_ONVIF_RELAY_OUTPUT_STATE_FROM_PROXY_FAILURE]: function (state, {deviceId, relayToken}) {
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.status.setOnvifsRelayOutputState[deviceId]) {
      Vue.set(state.status.setOnvifsRelayOutputState, deviceId, {});
    }

    Vue.set(state.status.setOnvifsRelayOutputState[deviceId], relayToken, 'failed');
  },

  [SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_REQUEST]: function (state) {
    state.status.onvifRelayOutputSettings = 'requested'
  },
  [SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_SUCCESS]: function (state, {data, deviceId}) {
    if (!deviceId) return
    if (data?.data && !("SetRelayOutputSettingsResponse" in data?.data)) return
    
    // 없는 경우 빈 객체를 먼저 설정
    if (!state.onvifsRelayOutputSettings[deviceId]) {
      Vue.set(state.onvifsRelayOutputSettings, deviceId, null);
    }

    // 이제 data를 deviceId에 해당하는 위치에 설정
    Vue.set(state.onvifsRelayOutputSettings, deviceId, true);

    state.status.onvifRelayOutputSettings = 'successful'
  },
  [SET_SITE_ONVIF_RELAY_OUTPUT_SETTINGS_FROM_PROXY_FAILURE]: function (state) {
    state.status.onvifRelayOutputSettings = 'failed'
  },

  [GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_REQUEST]: function (state) {
    state.status.getOnvifRelayOutputOptions = 'requested'
  },
  [GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_SUCCESS]: function (state) {
    state.status.getOnvifRelayOutputOptions = 'successful'
  },
  [GET_SITE_ONVIF_RELAY_OUTPUTS_OPTIONS_FROM_PROXY_FAILURE]: function (state) {
    state.status.getOnvifRelayOutputOptions = 'failed'
  },

  // RESETs
  [RESET_SITE_ONVIF_RELAY_FROM_PROXY]: function (state) {
    state.onvifsRelayInfos = {}
    state.onvifsRelayOutputSettings = {}
  },

  // OTHER
  // store dealer level
  [SET_RELAY_FINISH_TIME]: function (state, {siteId, deviceId, relayToken, finishTime}) {
    /* example
    { 
      1204: { // site id
        23331: { // camera id
          relay_1: 1234999231, // relay token: Estimated completion timestamp (momentary)
          relay_1: 1234999231,
        }
      }
    }
    */
  
    if (!state.relaysMomentaryFinishTime[siteId]) {
      Vue.set(state.relaysMomentaryFinishTime, siteId, {});
    }

    if (!state.relaysMomentaryFinishTime[siteId][deviceId]) {
      Vue.set(state.relaysMomentaryFinishTime[siteId], deviceId, {});
    }

    Vue.set(state.relaysMomentaryFinishTime[siteId][deviceId], relayToken, finishTime);
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}