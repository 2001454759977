
import Vue from 'vue'
export default {
  parseActivityLogData: function (data) {
    // filter out 'upload' event
    if (data.action == 'upload' && data.trigger != 'Schedule') return {}
    return {
      logId: data.id,
      logType: data.desc_id ? Vue.actlog.getDescType(data.desc_id) : null, // Description Id로 logType 정의하기
      logMeta: data.meta,
      logInfo: {
        descriptionId: data.desc_id,
        description: data.description,
        trigger: data.trigger,    // Bridge | Schedule | OE trigger
        statusText: data.status_text,
        action: data.action,     // armed | disarmed | alarm | restoral | upload |
        category: data.category,
        email: data.email,
        aiThumbnail: data.detection_summary,
        thumbnail: data?.thumbnail_url,
      },
      deviceInfo: {
        deviceId: data.device_id,
        deviceName: data.name,
      },
      eventInfo: {
        eventId: data.event_id,
        eventCode: data.event_code, // contactID, xml signal
      },
      statusInfo: {
        createdDate: data.created ? new Date(data.created) : null,
        timestamp: data.event_timestamp
      }
    }
  },
  parseDeviceActivityLogData: function (data) {
    const ids =  Vue.actlog.deviceDescIds
    let type, summaryEntityNames, detectionSummary, aiType
    if (ids.alarmVideo.includes(data.desc_id)) type = 'alarm'
    else if (ids.disarmedVideo.includes(data.desc_id)) type = 'activity'
    
    if (data?.summary_entity_names) summaryEntityNames = JSON.parse(data.summary_entity_names)
    if (data?.detection_summary) detectionSummary = JSON.parse(data.detection_summary)
    
    
    // 0 - person & vehicle
    // 1 - person
    // 2 - vehicle
    // 3 - none
    if (detectionSummary?.person?.thumbnail && detectionSummary?.vehicle?.thumbnail) aiType = 0
    else if (detectionSummary?.person?.thumbnail) aiType = 1
    else if (detectionSummary?.vehicle?.thumbnail) aiType = 2
    else aiType = 3

      

    return {
      logId: data?.id,
      eventId: data?.event_id,
      descId: data?.desc_id,
      type: type,
      timestamp: data.event_timestamp ? data.event_timestamp : Math.floor(new Date(data?.created).getTime()/1000),
      summaryEntityNames,
      detectionSummary,
      aiType,
    }
  },
}

