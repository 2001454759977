<template>
	<div></div>
</template>

<script>
import MarketingModal from '@/components/modals/Marketing'
import Vue from 'vue'
export default {
	name: 'marketing-manager',
	components: {
		MarketingModal,
	},
	data: function () {
		return {
			marketingVersion: "20250318", // 현재 마케팅 모달 버전
		}
	},
	watch: {
	},
	created: function () {
		this.checkMarketingModalStatus()
	},
	methods: {
		checkMarketingModalStatus: async function () {
			let marketing = await this.$tool.storageGetItem("chekt_marketing")
			if (!marketing || marketing !== this.marketingVersion) this.showMarketingModal()
		},
		showMarketingModal: function () {
      const ComponentClass = Vue.extend(MarketingModal)
      this.instance = new ComponentClass({
        store: this.$store,
        propsData: {
					marketingVersion: this.marketingVersion
        }
      })
      this.instance.$mount() // 메모리에 마운트
      document.body.appendChild(this.instance.$el) // 원하는 위치에 추가
		},
	}
}
</script>

<style scoped>
</style>