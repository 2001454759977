import Vue from 'vue'
import api from '@/apis/siteWebrtc'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  CREATE_WEBRTC_ICE_SERVERS_REQUEST,
  CREATE_WEBRTC_ICE_SERVERS_SUCCESS,
  CREATE_WEBRTC_ICE_SERVERS_FAILURE,
  UPDATE_WEBRTC_AUDIO_STATE,
  UPDATE_WEBRTC_AUDIO_SENDER_STATE,
  STORE_WEBRTC_AUDIO_CONTEXT,
  STORE_WEBRTC_AUDIO_SENDER_CONTEXT,
  STORE_WEBRTC_INFO_BRIDGES,
  SET_WEBRTC_VIDEO_LOG,
  REMOVE_WEBRTC_VIDEO_LOGS,

  SET_WEBRTC_VIDEO_DETAILS,
  SET_WEBRTC_VIDEO_DETAILS_TO_HTML,
  SET_CURRENT_PAGE_DEVICE_ID,
  UPDATE_WEBRTC_CONNECTION_STATUS,
  IS_STREAM_INACTIVE_MODE
} from '@/store/mutation-types'

let initialState = {
  webrtcBridges: [],
  webrtcAudioState: null,
  webrtcAudioContext: null,
  webrtcAudioSenderState: null,
  webrtcAudioSenderContext: null,
  webrtcVideoLog: [],
  showWebrtcVideoDetails: false,
  webrtcConnectionStatus: {},
  isStreamInactiveMode: false,
  siteWebrtcConnectionStatus: "",
  status: {
    getWebrtcIceServers: null,
  },
  webrtcVideoDetailsHtml: ``,
  currentPageDeviceId: null,
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  webrtcAudioState: function (state) {
    return state.webrtcAudioState
  },
  webrtcAudioContext: function (state) {
    return state.webrtcAudioContext
  },
  webrtcAudioSenderState: function (state) {
    return state.webrtcAudioSenderState
  },
  webrtcAudioSenderContext: function (state) {
    return state.webrtcAudioSenderContext
  },
  webrtcVideoLog: function (state) {
    return state.webrtcVideoLog
  },
  showWebrtcVideoDetails: function (state) {
    return state.showWebrtcVideoDetails
  },
  webrtcVideoDetailsHtml: function (state) {
    return state.webrtcVideoDetailsHtml
  },
  currentPageDeviceId: function (state) {
    return state.currentPageDeviceId
  },
  webrtcConnectionStatus: function (state) {
    return state.webrtcConnectionStatus
  },
  isStreamInactiveMode: function (state) {
    return state.isStreamInactiveMode
  },
  siteWebrtcConnectionStatus: function (state) {
    return state.siteWebrtcConnectionStatus
  }
}

// actions
const actions = {
  getWebrtcIceServers: function ({commit}, {siteId, provider}) {
    commit(CREATE_WEBRTC_ICE_SERVERS_REQUEST)
    return new Promise((resolve, reject) => {
      api.getWebrtcIceServers({siteId, provider}).then(
        res => {
          let data = res.body
          commit(CREATE_WEBRTC_ICE_SERVERS_SUCCESS, {data})
          resolve(res)
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(CREATE_WEBRTC_ICE_SERVERS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [UPDATE_WEBRTC_AUDIO_STATE]: function (state, data) {
    state.webrtcAudioState = data
  },
  [UPDATE_WEBRTC_AUDIO_SENDER_STATE]: function (state, data) {
    state.webrtcAudioSenderState = data
  },
  [STORE_WEBRTC_AUDIO_CONTEXT]: function (state, data) {
    state.webrtcAudioContext = data
  },
  [STORE_WEBRTC_AUDIO_SENDER_CONTEXT]: function (state, data) {
    state.webrtcAudioSenderContext = data
  },
  [STORE_WEBRTC_INFO_BRIDGES]: function (state, data) {
    state.webrtcBridges = data
  },
  [SET_WEBRTC_VIDEO_LOG]: function (state, log) {
    // 1000개 이상이면 삭제
    if (state.webrtcVideoLog.length > 1000) state.webrtcVideoLog.shift()
    
    state.webrtcVideoLog.push(log)
  },
  [REMOVE_WEBRTC_VIDEO_LOGS]: function (state) {
    state.webrtcVideoLog = []
  },
  [CREATE_WEBRTC_ICE_SERVERS_REQUEST]: function (state) {
    state.status.getWebrtcIceServers = 'requested'
  },
  [CREATE_WEBRTC_ICE_SERVERS_SUCCESS]: function (state, {data}) {
    state.status.getWebrtcIceServers = 'successful'
  },
  [CREATE_WEBRTC_ICE_SERVERS_FAILURE]: function (state) {
    state.status.getWebrtcIceServers = 'failed'
  },
  [SET_WEBRTC_VIDEO_DETAILS]: function (state, isShow) {
    state.showWebrtcVideoDetails = isShow
  },
  [SET_WEBRTC_VIDEO_DETAILS_TO_HTML]: function (state, html) {
    state.webrtcVideoDetailsHtml = html
  },
  [SET_CURRENT_PAGE_DEVICE_ID]: function (state, deviceId) {
    state.currentPageDeviceId = deviceId
  },
  [UPDATE_WEBRTC_CONNECTION_STATUS]: function (state, status) {
    if (!status.bridgeId) return;

    if (status.status === "gathering" || status.status === "complete" || status.status === "connecting" || status.status === "checking") {
      status.status = "attempting connection";
    }
    else if (status.status === "connected") {
      status.status = "optimized";
    }

    // bridgeId가 아직 등록되지 않은 경우 Vue.set을 사용해 초기화
    if (!state.webrtcConnectionStatus[status.bridgeId]) {
      Vue.set(state.webrtcConnectionStatus, status.bridgeId, {});
    }

    if (!state.webrtcConnectionStatus[status.bridgeId]["front"]) {
      Vue.set(state.webrtcConnectionStatus[status.bridgeId], "front", "");
    }
    if (!state.webrtcConnectionStatus[status.bridgeId]["back"]) {
      Vue.set(state.webrtcConnectionStatus[status.bridgeId], "back", "");
    }
  
    // 해당 bridgeId의 특정 type에 대해 status 값을 Vue.set으로 등록
    Vue.set(state.webrtcConnectionStatus[status.bridgeId], status.type, status.status);

    // 각 bridge의 상태를 개별적으로 판단
    const bridgeStatuses = Vue.webrtc.bridges.map(bridge => {
      const statusObj = state.webrtcConnectionStatus?.[bridge.deviceId];
      if (!statusObj) return "unreachable"; // 상태 정보가 없으면 연결 안됨으로 처리

      const keys = Object.keys(statusObj); // 예: ["front", "back"]
      // 각 항목의 상태가 "connected"인지 확인
      const connectedCount = keys.filter(key => statusObj[key] === "optimized").length;
      const attemptingCount = keys.filter(key => statusObj[key] === "attempting connection").length;

      if (connectedCount === keys.length) {
        return "optimized";
      } else if (attemptingCount > 0) {
        return "attempting connection";
      } else {
        return "unreachable";
      }
    });

    let overallStatus = "";

    // 전부 optimized인 경우
    if (bridgeStatuses.every(status => status === "optimized")) {
      overallStatus = "optimized";
    }
    // 하나라도 optimized가 있으면 (전부 optimized는 위에서 걸러짐)
    else if (bridgeStatuses.some(status => status === "optimized")) {
      overallStatus = "partially optimized";
    }
    // optimized된게 하나도 없으면서 하나라도 attempting connection이면
    else if (bridgeStatuses.some(status => status === "attempting connection")) {
      overallStatus = "attempting connection";
    } 
    else {
      overallStatus = "unreachable";
    }

    // 예시로 data 혹은 computed에 결과 저장
    state.siteWebrtcConnectionStatus = overallStatus;
  },
  [IS_STREAM_INACTIVE_MODE]: function (state, isInactive) {
    state.isStreamInactiveMode = isInactive
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}