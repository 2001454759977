import Vue from 'vue'
var moment = require('moment')//for get time

export default {
  apiPrefix: '/api/v2/sites',
  // FILTER - category
  // USE 1 - Get only older logs             - targetLogId={{firstLogId}}
  // USE 2 - Get only newer logs             - targetLogId={{lastLogId}}, toGetNewLogs=true
  // USE 3 - Get logs with time (only older) - targetLogId={{firstLogId}}, stime={{start time}}, etime={{end time}}
  // USE 4 - Get logs with time              - stime={{start time}}, etime={{end time}}
  getSiteActivityLogs: function ({siteId, stime, etime, targetLogId, toGetNewLogs, eventTypes, siteTimezone, logPeriod}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/activity_logs`
    // OPTION 1 - limit total event to 50
    let query = `?limit=50`
    // OPTION 2 - category
    if (eventTypes) query += `&filter=log.desc_id,${eventTypes}`
    // OPTION 3 - to retrieve ONLY logs not yet retrieved
    if (targetLogId) {
      if (toGetNewLogs) {
        query += `&dir=asc&sort=id`
        query += `&filter=log.id,${targetLogId},gt`
      }
      else {
        query += `&dir=desc&sort=id`
        query += `&filter=log.id,${targetLogId},lt`
      }
    }
    else {
      // 테스트결과 logid로 sorting하는게 훨씬 속도가 빨라서 변경함.
      if (etime) query += `&dir=desc&sort=id`
      else query += `&dir=desc&sort=id&filter=log.id,0,gt`
      // query += '&dir=desc&sort=log.created'
    }
    // OPTION 4 - stime (UTC) - e.g. 2020-05-12 05:00:00 gte = ~ 이상

    // ********************************** 여기서 Activity log 기간 설정하기! 
    let period = 30
    if (logPeriod) period = logPeriod
    // 사이트 타임존으로 색깔 입히기
    let nowWithSiteTimezone = moment.tz(siteTimezone)
    // ??일전 00:00:00 으로 변경
    let nowBefore30days = nowWithSiteTimezone.hours(0).minutes(0).seconds(0).hours(-24*period)
    // 사이트 타임존기준으로 UTC시간 구하기
    stime = nowBefore30days.tz('UTC').format('YYYY-MM-DD HH:mm:ss')
    if (stime) query += `&filter=log.created,${stime},gte`
    // ********************************** 여기서 Activity log 기간 설정하기! 

    // OPTION 5 - etime (UTC) - e.g. 2020-05-14 05:00:00 lte = ~ 이하 
    if (etime) query += `&filter=log.created,${etime},lt`

    // ACTION
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  getDeviceActivityLogs: function ({siteId, stime, deviceId, toGetNewLogs, etime, eventTypes, siteTimezone, targetLogId, limit}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/activity_logs`
    
    // REQUIRE 1 - limit defaults to 50, use the provided value
    limit = Number.isInteger(limit) ? limit : 50;
    let query = `?limit=${limit}`

    // REQUIRE 2 - device Id
    if (!deviceId) return
    query += `&filter=log.device_id,${deviceId}`

    // OPTION 1 - category
    if (eventTypes) query += `&filter=log.desc_id,${eventTypes}`

    // OPTION 2 - to retrieve ONLY logs not yet retrieved
    if (targetLogId) {
      if (toGetNewLogs) {
        query += `&dir=asc&sort=id`
        query += `&filter=log.id,${targetLogId},gt`
      }
      else {
        query += `&dir=desc&sort=id`
        query += `&filter=log.id,${targetLogId},lt`
      }
    }
    else {
      // 테스트결과 logid로 sorting하는게 훨씬 속도가 빨라서 변경함.
      if (etime) query += `&dir=desc&sort=id`
      else query += `&dir=desc&sort=id&filter=log.id,0,gt`
      // query += '&dir=desc&sort=log.created'
    }

    // OPTION 3 - stime (UTC) - e.g. 2020-05-12 05:00:00 gte = ~ 이상
    if (stime) {
      const localTime = moment.tz(stime, siteTimezone);
      const utcTime = localTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
      query += `&filter=log.created,${utcTime},gte`
    }

    // OPTION 4 - etime (UTC) - e.g. 2020-05-14 05:00:00 lte = ~ 이하 
    if (etime) {
      const localTime = moment.tz(etime, siteTimezone);
      const utcTime = localTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
      query += `&filter=log.created,${utcTime},lt`
    }

    // ACTION
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  getDevicePlaybackActivityLogs: function ({siteId, stime, deviceId, etime, eventTypes, siteTimezone}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/activity_logs`
    // REQUIRE 1 - limit total event to 50
    let query = `?limit=100`

    // REQUIRE 2 - limit total event to 50
    if (!deviceId) return
    query += `&filter=log.device_id,${deviceId}`

    // OPTION 1 - category
    if (eventTypes) query += `&filter=log.desc_id,${eventTypes}`

    // OPTION 2 - to retrieve ONLY logs not yet retrieved
    if (etime) query += `&dir=desc&sort=id`
    else query += `&dir=desc&sort=id&filter=log.id,0,gt`

    // OPTION 3 - stime (UTC) - e.g. 2020-05-12 05:00:00 gte = ~ 이상
    const localTime = moment.tz(stime, siteTimezone);
    const utcTime = localTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
    if (stime) query += `&filter=log.created,${utcTime},gte`

    // // OPTION 4 - etime (UTC) - e.g. 2020-05-14 05:00:00 lte = ~ 이하 
    // if (etime) query += `&filter=log.created,${etime},lt`

    // ACTION
    return Vue.http.get(Vue.env.apiPath + apiDetailPath + query, Vue.auth.getBearerHeader())
  },
  getSiteActivityLogAudioUrl: function ({siteId, logId}) {
    let apiDetailPath = this.apiPrefix + `/${siteId}/log/activities/${logId}/audio_signed_url`
    return Vue.http.get(Vue.env.apiPath + apiDetailPath, Vue.auth.getBearerHeader())
  },
}
