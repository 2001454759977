<template>
  <div ref="alert" class="alert">
      <div class="alert-contents">
        <div class="title">Inactivity Pause</div>
        <div class="desc" v-if="progress < 100">Due to prolonged inactivity, you will soon enter inactive mode and your connection to the camera will be terminated.</div>
        <div class="desc" v-else>Cameras have been paused due to prolonged inactivity, as no movement has been detected for an extended period.</div>
        <div  v-if="progress < 100" class="progress-container">
          <div class="progress-bar" :style="{ width: progress + '%' }"></div>
        </div>
        <div class="actions">
            <ion-button v-if="progress < 100" @click="onClickContinue()">Continue to See</ion-button>
            <ion-button v-else @click="onClickResume()">Resume</ion-button>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  //Cameras are paused due to Inactivity
  data() {
    return {
      progress: 0,
      timer: null,
      startTime: null,
      duration: 30000,
    };
  },
  created() {
    
  },
  mounted() {

    this.startTimer()
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    startTimer() {
      // 기존 타이머가 있다면 정리
      if (this.timer) {
        clearInterval(this.timer);
      }
      // 진행률 초기화 및 시작 시간 기록
      this.progress = 0;
      this.startTime = Date.now();

      // 100ms마다 진행률 업데이트
      this.timer = setInterval(async() => {
        const elapsed = Date.now() - this.startTime; // 경과 시간(ms)
        this.progress = Math.min((elapsed / this.duration) * 100, 100);

        // 30초가 지나면 타이머 정리
        if (this.progress >= 100) {
          this.$store.commit('IS_STREAM_INACTIVE_MODE', true);
          await this.$webrtc.clearAllPeers()
          clearInterval(this.timer);
          this.timer = null;
        }
      }, 100);
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    onClickResume() {
      this.$store.commit('IS_STREAM_INACTIVE_MODE', false);
      this.$emit('onClickResume');
    },
    onClickContinue() {
      this.$emit('onClickContinue');
    }
  }

}
</script>

<style scoped>
.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}
.alert-contents {
  background-color: var(--ion-color-light);
  padding: 24px 24px 14px 24px;
  border-radius: 10px;
  max-width: 360px;
}
.title {
  font-size: 18px;
  font-weight: 600;
  color: var(--ion-text-high);
  padding-bottom: 10px;
}
.desc {
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-text-medium);
  padding-bottom: 30px;
}
.actions {
  display: flex;
  justify-content: flex-end;
}
.progress-container {
  width: 100%;
  background-color: #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar {
  height: 10px;
  background-color: #4caf50;
  transition: width 0.1s linear;
}

</style>