import Vue from 'vue'
import api from '@/apis/siteEventStatistics'
import parser from '@/models/siteEventStatistics'
import {
  RESET_STATE,
  PROCESS_API_SUCCESS,
  PROCESS_API_FAILURE,
  INIT_SITE_EVENT_STATISTICS,
  DATE_PICKER_DATE_CHANGED,
  DATE_PICKER_DATE_SELECTED,
  GET_SITE_EVENT_STATISTICS_REQUEST,
  GET_SITE_EVENT_STATISTICS_SUCCESS,
  GET_SITE_EVENT_STATISTICS_FAILURE,
  GET_DEVICE_EVENT_STATISTICS_REQUEST,
  GET_DEVICE_EVENT_STATISTICS_SUCCESS,
  GET_DEVICE_EVENT_STATISTICS_FAILURE,
} from '@/store/mutation-types'

let initialState = {
  statisticsLogs: [],
  selectedDate: null,
  calendarSelected: false,
  statisticsDeviceLogs: [],
  status: {
    getStatisticsLogs: null,
    getDeviceStatisticsLogs: null,
  },
}

// initial state
const state = Vue.util.extend({}, initialState)

// getters
const getters = {
  selectedDate: function (state) {
    return state.selectedDate
  },
  calendarSelected: function (state) {
    return state.calendarSelected
  },
  siteStatisticsLogs: function (state) {
    return state.statisticsLogs || []
  },
  statisticsDeviceLogs: function (state) {
    return state.statisticsDeviceLogs || []
  },
  statusGetSiteStatisticsLogs: function (state) {
    return state.status.getStatisticsLogs
  },
  statusGetDeviceStatisticsLogs: function (state) {
    return state.status.getDeviceStatisticsLogs
  },
}

// actions
const actions = {
  getSiteEventStatistics: function ({commit}, {siteId, siteTimezone, stime, etime}) {
    commit(GET_SITE_EVENT_STATISTICS_REQUEST, {siteId})
    // API CALL
    return new Promise((resolve, reject) => {
      api.getSiteEventStatistics({siteId, stime, etime}).then(
        res => {
          let data = res.body
          commit(GET_SITE_EVENT_STATISTICS_SUCCESS, {data, siteTimezone})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_SITE_EVENT_STATISTICS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
  getDeviceEventStatistics: function ({commit}, {siteId, siteTimezone, stime, etime, deviceId}) {
    commit(GET_DEVICE_EVENT_STATISTICS_REQUEST, {siteId})
    // API CALL
    return new Promise((resolve, reject) => {
      api.getSiteEventStatistics({siteId, stime, etime}).then(
        res => {
          let data = res.body
          commit(GET_DEVICE_EVENT_STATISTICS_SUCCESS, {data, deviceId, siteTimezone})
          resolve()
          commit(PROCESS_API_SUCCESS)
        },
        err => {
          commit(GET_DEVICE_EVENT_STATISTICS_FAILURE)
          reject({
            status: err.status,
            statusText: err.body,
          })
          commit(PROCESS_API_FAILURE, {
            status: err.status,
            statusText: err.body,
            origin: window.location.origin,
            err: Vue.tool.parseToStringify(err),
          })
        }
      )
    })
  },
}

// mutations
const mutations = {
  [RESET_STATE]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [INIT_SITE_EVENT_STATISTICS]: function (state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f])
    }
  },
  [DATE_PICKER_DATE_CHANGED]: function (state, selectedDate) {
    state.selectedDate = selectedDate
  },
  [DATE_PICKER_DATE_SELECTED]: function (state, calendarSelected) {
    state.calendarSelected = calendarSelected
  },
  [GET_SITE_EVENT_STATISTICS_REQUEST]: function (state) {
    state.status.getStatisticsLogs = "requested"
  },
  [GET_SITE_EVENT_STATISTICS_SUCCESS]: function (state, { data, siteTimezone }) {
    let statisticsInfo = parser.parseEventStatisticsData(data, siteTimezone)
    
    // SORT - 2020/1/1 - 2020/12/31
    statisticsInfo.sort((a,b) => a-b)

    state.statisticsLogs = statisticsInfo
    state.status.getStatisticsLogs = "successful"
  },
  [GET_SITE_EVENT_STATISTICS_FAILURE]: function (state) {
    state.status.getStatisticsLogs = "failed"
  },
  [GET_DEVICE_EVENT_STATISTICS_REQUEST]: function (state) {
    state.status.getDeviceStatisticsLogs = "requested"
  },
  [GET_DEVICE_EVENT_STATISTICS_SUCCESS]: function (state, {data, siteTimezone, deviceId}) {
    let statisticsDeviceInfo = parser.parseDeviceEventStatisticsData(data, siteTimezone, deviceId)

    // SORT - 2020/1/1 - 2020/12/31
    statisticsDeviceInfo.sort((a,b) => a-b)

    state.statisticsDeviceLogs = statisticsDeviceInfo
    state.status.getDeviceStatisticsLogs = "successful"
  },
  [GET_DEVICE_EVENT_STATISTICS_FAILURE]: function (state) {
    state.status.getDeviceStatisticsLogs = "failed"
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}